import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import ScrollUp from "../components/ScrollUp"
import Accordion from "../components/Accordion"
import SEO from "../components/SEO"
import { StaticQuery, graphql } from "gatsby"
export default function Hinnat() {
  const intl = useIntl()
  return (
    <StaticQuery
      query={graphql`
        query PriceQuery {
          allSanityPrice {
            nodes {
              Language
              maintitle
              subtitle
              question_answer {
                content
                title
                _key
              }
              question_answer_title
              table_list {
                _key
                table_head
                title
                table_body {
                  _key
                  column1
                  column2
                  column3
                  column4
                  column5
                  column6
                }
              }
            }
          }
        }
      `}
      render={data => {
        let resource = null

        if (intl.locale === "en") {
          resource = data.allSanityPrice.nodes.filter(
            item => item.Language === "en"
          )
          resource = resource[0]
          console.log(resource)
        } else {
          resource = data.allSanityPrice.nodes.filter(
            item => item.Language === "fi"
          )
          resource = resource[0]
          console.log(resource)
        }
        return (
          <>
            {resource && (
              <>
                <SEO />
                <div className="wrapper">
                  <Header link="/hinnat" />
                  <section className="py-0">
                    <div className="container col-padding-0">
                      <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2 mt-50">
                        <div className="heading text-center mb-40">
                          <h2 className="heading__title">
                            {resource.maintitle}
                          </h2>
                          <span className="heading__subtitle">
                            {resource.subtitle}
                          </span>
                        </div>
                      </div>
                      {resource.table_list.map(item => {
                        return (
                          <div key={item._key} className="mb-50">
                            <h5>{item.title}</h5>
                            <table
                              id="price-customer-table"
                              className={
                                item.table_head && item.table_head.length > 1
                                  ? "table-scrolling"
                                  : ""
                              }
                            >
                              <tbody>
                                {item.table_head &&
                                item.table_head.length > 1 ? (
                                  <tr>
                                    {item.table_head.map((item, index) => {
                                      return <th key={index}>{item}</th>
                                    })}
                                  </tr>
                                ) : (
                                  <></>
                                )}
                                {item.table_body.map(row => {
                                  return (
                                    <tr key={row._key}>
                                      {Object.keys(row)
                                        .filter(
                                          key => row[key] && key !== "_key"
                                        )
                                        .map((column, index) => {
                                          return (
                                            <td key={index}>{row[column]}</td>
                                          )
                                        })}
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        )
                      })}
                    </div>
                  </section>

                  <section className="faq pt-50 pb-70">
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                          <div className="heading text-center mb-50">
                            <h2 className="heading__title">
                              {resource.question_answer_title}
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div className="row" id="accordion">
                        {resource.question_answer.map((item, index) => {
                          return (
                            <div
                              key={item._key}
                              className="col-sm-12 col-md-12 col-lg-6"
                            >
                              <Accordion
                                collapseId={`collapse-${item._key}`}
                                title={item.title}
                                desc={item.content}
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </section>
                  <Footer />

                  <ScrollUp />
                </div>
              </>
            )}
          </>
        )
      }}
    />
  )
}
